import React, { useState } from 'react'
import { graphql, useQuery, useMutation } from 'react-apollo'
import { compose } from 'recompose'
import { Form,
  Button,
  message,
  Layout,
  InputNumber,
  DatePicker,
  Input,
  Spin,
  Breadcrumb
} from 'antd'
import { Link } from 'react-router-dom'
import EventSider from '~/components/Events/Event/Sider'
import TopContent from '~/components/Events/Event/TopContent'
import _get from 'lodash/get'
import eventWithdrawRequestQuery from '~/graphql/queries/eventWithdrawRequest.gql'
import eventQuery from '~/graphql/queries/miniEvent.gql'
import createEventWithdrawRequestMutation from '~/graphql/mutations/createEventWithdrawRequest.gql'
import updateEventWithdrawRequestMutation from '~/graphql/mutations/updateEventWithdrawRequest.gql'
import eventSummaryTotal from '~/graphql/queries/eventSummaryTotal.gql'

import { withTranslation } from 'react-i18next'

const { TextArea } = Input
const dateFormat = 'DD/MM/YYYY'
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}


const WithdrawRequests = (props) => {
  const { match: { params: { eventId } }, t } = props
  const { getFieldDecorator } = props.form
  const [isLoading, setIsLoading] = useState(false)

  const { data, loading } = useQuery(eventWithdrawRequestQuery, {
    variables: { eventId }
  })
  const { data: data2, loading: loadingEvent} = useQuery(eventQuery, {
    variables:{
      _id: eventId
    }
  })

  const { data: data3, loading: loadingTotal } = useQuery(eventSummaryTotal, {
    variables: { eventId }
  })

  const [createWithdraw] = useMutation(createEventWithdrawRequestMutation)
  const [updateWithdraw] = useMutation(updateEventWithdrawRequestMutation)

  if(loading || loadingEvent || loadingTotal){
    return <Spin>loading</Spin>
  }
  const event = data2.event

  const currency = event.settings.payment.currency

  // console.log(data.eventWithdrawRequest, 'eventWithdrawRequest')
  // console.log(event, 'eventS')
  getFieldDecorator(`eventId`, {
    initialValue: _get(event, '_id')
  })

  const handleSubmit = () => {
    setIsLoading(true)
    const eventWithdrawRequestId = _get(props, 'match.params._id')
    const eventId = _get(props, 'match.params.eventId')
    props.form.validateFieldsAndScroll((err, record) => {
      // console.log(record, 'record')
      if (err) {
        message.error(err)
        setIsLoading(false)
        return
      }
      if(eventWithdrawRequestId){
        updateWithdraw({
          variables: {
            eventId,
            record
          }
        }).then(result => {
          message.success('Saved!')
          setIsLoading(false)
        }).catch(err => {
          message.error(err.message)
          setIsLoading(false)
          props.history.push(`/fetchPage?link=/events/${eventId}/withdraw-requests/${eventWithdrawRequestId}/edit`)
        })
      } else {
        createWithdraw({ 
          variables: {
          eventId,
          record
          }
        }).then(result => {
          message.success('Saved!')
          setIsLoading(false)
        }).catch(err => {
          message.error(err.message)
          setIsLoading(false)
          props.history.push(`/events/${eventId}/withdraw-requests`)
        })
      }

    })


  }

  return(
    <Layout>
      <Layout.Content>
        <TopContent
          title={'Withdraw'}
          pattern='withdraw'
        />
        <div className="innerMainContentWrapper">
          <EventSider />
          <div className="middleContent">
            <Breadcrumb separator=">" style={{padding: '10px 0px'}}>
              <Breadcrumb.Item><Link to={`/events/${eventId}/withdraw-requests/`}>ถอนเงิน</Link></Breadcrumb.Item>
              <Breadcrumb.Item><Link to={`/events/${eventId}/withdraw-requests/create`}>ขอถอนเงิน</Link></Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ backgroundColor: "#fff", borderRadius: '8px', padding: '20px', marginBottom: '20px'}}>
              <p>ยอดเงินคงเหลือ</p>
              <p style={{ fontSize: '28px', fontWeight: 'bold'}}>{data3.eventSummaryTotal.total} {currency}</p>
            </div>
            <div style={{ backgroundColor: "#fff", borderRadius: '8px', padding: '20px'}}>
              <p>
              * ยอดเงินการถอนครั้งละไม่เกิน 2,000,000 บาท
              </p>
              <p>* แจ้งการถอนเงินภายในวันศุกร์ เพื่อให้ระบบสามารถดำเนินการโอนเงินในวันศุกร์ถัดไป</p>
              <Form>
                <Form.Item label="จำนวนเงิน" {...formItemLayout}>
                  {getFieldDecorator(`amount`, {
                    initialValue: _get(data.eventWithdrawRequest, 'amount'),
                    rules: [{ required: true, message: 'Please input amount' }]
                  })(<InputNumber/>)}
                </Form.Item>
                <Form.Item label={t('transfer.withdraw.number')} {...formItemLayout}>
                  {getFieldDecorator(`bankAccountNumber`, {
                    initialValue: _get(data.eventWithdrawRequest, 'bankAccountNumber'),
                    rules: [{ required: true, message: 'Please input bankAccountNumber' }]
                  })(<Input/>)}
                </Form.Item>
                <Form.Item label={t('transfer.withdraw.bank')} {...formItemLayout}>
                  {getFieldDecorator(`bankAccountBrand`, {
                    initialValue: _get(data.eventWithdrawRequest, 'bankAccountBrand'),
                    rules: [{ required: true, message: 'Please input bankAccountBrand' }]
                  })(<Input/>)}
                </Form.Item>
                <Form.Item label={t('transfer.withdraw.account')} {...formItemLayout}>
                  {getFieldDecorator(`bankAccountName`, {
                    initialValue: _get(data.eventWithdrawRequest, 'bankAccountName'),
                    rules: [{ required: true, message: 'Please input bankAccountName' }]
                  })(<Input/>)}
                </Form.Item>

                <Form.Item label={t('transfer.withdraw.remark')} {...formItemLayout}>
                {getFieldDecorator(`remarks`, {
                  initialValue: _get(data.eventWithdrawRequest, 'remarks'),
                  rules: [{ required: true, message: 'Please input remarks' }]
                })(<TextArea/>)}
              </Form.Item>

              <Form.Item style={{textAlign: 'center'}}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
                loading={isLoading}
              >
                {t('transfer.withdraw.save')}
              </Button>{' '}
              <Button loading={isLoading} type="ghost" onClick={() => window.history.back()}>
                {t('transfer.withdraw.back')}
              </Button>
            </Form.Item>

              </Form>
            </div>
          </div>
        </div>

      </Layout.Content>
    </Layout>
  )

}

export default compose(
  Form.create(),
  withTranslation()
)(WithdrawRequests)