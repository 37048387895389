import React, { Component, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
// import { graphql, gql, compose } from 'react-apollo'
// import '../../node_modules/grommet/scss/grommet-core/index.scss'
// import '../index.css'
// import EventCard from '../components/EventCard'
// import GrommetApp from 'grommet/components/App'
// import List from 'grommet/components/List'
// import ListItem from 'grommet/components/ListItem'
// import { Layout, Menu, Table , Icon} from 'antd'

// import EventCreate from './EventCreate'
// import EventEditEdit from './EventEditEdit'
const EventEdit = lazy(() => import('~/components/Events/Event/Edit'))
// import EventCreateComplete from './EventCreateComplete'
// import EventCreate from './Event'
const EventList = lazy(() => import('./Events/List'))
// import EventEdit from './EventEdit'
const EventParticipants = lazy(() => import('./EventParticipants'))
const Participant = lazy(() => import('./Participant'))
import ParticipantEdit from './ParticipantEdit'
import EventRegistrations from '~/components/Events/Event/Registrations'
// import EventPayments from './EventPayments'
const EventStatistics = lazy(() => import('./EventStatistics'))
// import EventStatReports from './EventStatReports'
import EventStatReports from '~/components/Events/Event/Statistics'
// import Registration from './Registration'
import Registration from './Registration'
import RegistrationEdit from './RegistrationEdit'
import Payment from './Payment'
import EventTickets from './EventTickets'
import EventTools from '~/components/Events/Event/Tools'
import EventImports from '~/components/Events/Event/Imports/Imports'
import EventDownloads from '~/components/Events/Event/Downloads'
import EventProducts from '~/components/Events/Event/Products/Products'
import EventProductForm from '~/components/Events/Event/Products/Form'
import EventProductTypeAdd from '~/components/Events/Event/ProductTypes/ProductTypes'
import EventProductTypeForm from '~/components/Events/Event/ProductTypes/Form'
import EventProductTypeFormEdit from '~/components/Events/Event/ProductTypes/FormEdit'
import EventRefundRequests from './EventRefundRequests'
import EventCancelRequests from '~/components/Events/Event/CancelRequests/CancelRequests'
import EventRuns from '~/components/Events/Event/Runs'
import QuickBooks from './QuickBooks'
// import PaymenyStatistics from '~/components/Events/Event/PaymentStatistics'
import WithdrawRequests from '~/components/Events/Event/WithdrawRequest/WithdrawRequests'
import WithdrawRequestForm from '~/components/Events/Event/WithdrawRequest/Form'
import WithdrawRequestDetail from '~/components/Events/Event/WithdrawRequest/Detail'
import PaymenySummary from '~/components/Events/Event/PaymentSummary'
import PaymenySummaryV2 from '~/components/Events/Event/PaymentSummaryV2'
import RefundRequests from './RefundRequest'
import CancelRequests from '~/components/Events/Event/CancelRequests/CancelRequest'
// import OrderItemSummary from './OrderItemSummary'
// import Orders from './Orders'
import Orders from '~/components/Orders'
import EventOrderItems from './EventOrderItems'
import exportRegistration from './ExportRegistration'
import Ticket from './Ticket'
import RegistrationPayments from './RegistrationPayments'
import RegistrationParticipants from './RegistrationParticipants'
import CouponCodeTypes from '~/components/CouponCodeTypes/CouponCodeTypes'
import { Row, Col } from 'antd'

// import Error
import Error404 from '~/components/Error/404'
import styled from 'styled-components'
const WContent = styled.div`
  /* max-width: 1040px;
  margin: auto;
  margin-top: 33px; */
  /* margin-top: 2px; */
`
class Events extends Component {
  render () {
    return (<div>
      {/* <div className="topContent">
        <Row>
          <Col span={8}>
            <span className="title">
              งานวิ่งทั้งหมด
            </span>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            
          </Col>
        </Row>
      </div> */}
      <WContent>
        <Switch>
          <Route
            exact
            path="/events"
            component={EventList}
          />
          <Route
            exact
            path="/events/create"
            component={EventEdit}
          />
          <Route
            exact
            path="/events/:eventId/edit"
            component={EventEdit}
          />
          <Route
            exact
            path="/events/:eventId/edit::step"
            component={EventEdit}
          />
          <Route
            exact
            path="/events/:eventId/participants"
            component={EventParticipants}
          />
          <Route
            exact
            path="/events/:eventId/participants/:id"
            component={Participant}
          />
          <Route
            exact
            path="/events/:eventId/participants/:id/edit"
            component={ParticipantEdit}
          />
          <Route
            exact
            path="/events/:eventId/export-registration"
            component={exportRegistration}
          />
          <Route
            exact
            path="/events/:eventId/registrations"
            component={EventRegistrations}
          />
          <Route
            exact
            path="/events/:eventId/registrations/:id"
            component={Registration}
          />
          <Route
            exact
            path="/events/:eventId/registrations/:id/edit"
            component={RegistrationEdit}
          />
          {/* <Route
            exact
            path="/events/:eventId/payments"
            component={EventPayments}
          /> */}
          <Route
            exact
            path="/events/:eventId/statsv1"
            component={EventStatistics}
          />
          <Route
            exact
            path="/events/:eventId/stats"
            component={EventStatReports}
          />
          <Route
            exact
            path="/events/:eventId/stats-report"
            component={EventStatReports}
          />
          <Route
            exact
            path="/events/:eventId/payments/:id"
            component={Payment}
          />
          <Route
            exact
            path="/events/:eventId/tickets"
            component={EventTickets}
          />
          <Route
            exact
            path="/events/:eventId/tickets/:id"
            component={Ticket}
          />
          <Route
            exact
            path="/events/:eventId/downloads"
            component={EventDownloads}
          />
          <Route
            exact
            path="/events/:eventId/items"
            component={EventOrderItems}
          />
          <Route
            exact
            path="/events/:eventId/products"
            component={EventProducts}
          />
          <Route
            exact
            path="/events/:eventId/products/create"
            component={EventProductForm}
          />
          <Route
            exact
            path="/events/:eventId/products/:_id/edit"
            component={EventProductForm}
          />
          <Route
            exact
            path="/events/:eventId/products/:productId/addType"
            component={EventProductTypeAdd}
          />
          <Route
            exact
            path="/events/:eventId/products/:productId/addType/create"
            component={EventProductTypeForm}
          />
          <Route
            exact
            path="/events/:eventId/products/:productId/addType/:_id/edit"
            component={EventProductTypeFormEdit}
          />
          <Route
            exact
            path="/events/:eventId/registrations/:registrationId/payments"
            component={RegistrationPayments}
          />
          <Route
            exact
            path="/events/:eventId/registrations/:registrationId/participants"
            component={RegistrationParticipants}
          />
          <Route
            exact
            path="/events/:eventId/coupon-code-types/*"
            component={CouponCodeTypes}
          />
          <Route
            exact
            path="/events/:eventId/refund-requests/:refundRequestId"
            component={RefundRequests}
          />
          <Route
            exact
            path="/events/:eventId/refund-requests"
            component={EventRefundRequests}
          />
          <Route
            exact
            path="/events/:eventId/cancel-requests/:cancelRequestId"
            component={CancelRequests}
          />
          <Route
            exact
            path="/events/:eventId/cancel-requests"
            component={EventCancelRequests}
          />
          <Route
            exact
            path="/events/:eventId/runs"
            component={EventRuns}
          />
          <Route
            exact
            path="/events/:eventId/quick-books"
            component={QuickBooks}
          />
          <Route
            exact
            path="/events/:eventId/orders"
            component={Orders}
          />
          {/* <Route
            exact
            path="/events/:eventId/payment-statistics"
            component={PaymenyStatistics}
          /> */}
          <Route
            exact
            path="/events/:eventId/payment-summary"
            component={PaymenySummary}
          />
          <Route
            exact
            path="/events/:eventId/payment-summaryv2"
            component={PaymenySummaryV2}
          />
          <Route
            exact
            path="/events/:eventId/tools"
            component={EventTools}
          />
          <Route
            exact
            path="/events/:eventId/withdraw-requests"
            component={WithdrawRequests}
          />
          <Route
            exact
            path="/events/:eventId/withdraw-requests/create"
            component={WithdrawRequestForm}
          />
          <Route
            exact
            path="/events/:eventId/withdraw-requests/:withdrawId"
            component={WithdrawRequestDetail}
          />
          <Route
            exact
            path="/events/:eventId/withdraw-requests/:_id/edit"
            component={WithdrawRequestForm}
          />
          <Route
            exact
            path="/events/:eventId/imports/privilege"
            component={EventImports}
          />
          <Route
            exact
            path="/events/:eventId/imports/tracking"
            component={EventImports}
          />
          <Route
            exact
            path="*"
            component={Error404}
          />
        </Switch>
      </WContent>
    </div>)
  }
}

export default Events

/**
 *       <Table columns={columns}
        dataSource={events}
        loading={loading}
        locale={{emptyText: 'No Data'}}
        onRowClick={onRowClick}
        />
 */
