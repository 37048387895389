
import React, { useState } from 'react'
import { graphql, useQuery, useMutation } from 'react-apollo'
import { compose } from 'recompose'
import { Form,
  Button,
  message,
  Layout,
  InputNumber,
  DatePicker,
  Input,
  Spin,
  Breadcrumb
} from 'antd'
import { Link } from 'react-router-dom'
import EventSider from '~/components/Events/Event/Sider'
import TopContent from '~/components/Events/Event/TopContent'
import _get from 'lodash/get'
import eventWithdrawRequestQuery from '~/graphql/queries/eventWithdrawRequest.gql'
import eventQuery from '~/graphql/queries/miniEvent.gql'
import createEventWithdrawRequestMutation from '~/graphql/mutations/createEventWithdrawRequest.gql'
import updateEventWithdrawRequestMutation from '~/graphql/mutations/updateEventWithdrawRequest.gql'
import eventWithdrawRequestPrefieldQuery from '~/graphql/queries/eventWithdrawRequestPrefield.gql'
import Loading from '~/components/Loading/index'
import EventNotFound from '~/components/Error/Eventnotfound'
// import EventWithdrawRequestNotFound from '~/components/Error/EventWithdrawRequestnotfound'
import { inject, observer } from 'mobx-react'
import withRepeaterHandler from '~/utils/withRepeaterHandler'
import moment from 'moment-timezone'
import { withTranslation } from 'react-i18next'


const WithdrawDetail = () => {

  return(
    <Layout>
      <Layout.Content>
        <TopContent
          title={'Withdraw'}
          pattern='withdraw'
        />
      </Layout.Content>
    </Layout>
  )

}

export default WithdrawDetail