import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import adminQuery from '~/graphql/queries/adminUser.gql'
import eventQuery from '~/graphql/queries/eventSider/event.gql'
import _includes from 'lodash/includes'
import compose from 'lodash/flowRight'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import { useTranslation } from 'react-i18next'
// import {
//   //  gql,
//   graphql
// } from 'react-apollo'
import { Menu, Icon, Layout } from 'antd'
// import adminQuery from '~/graphql/queries/adminUser.gql'
// import '../styles/responsive-menu.css'
import clientAuth from '~/utils/client-auth'
import config from '~/config'

const { Sider } = Layout
const { SubMenu } = Menu

const EventSider = (props) => {
  if(window.innerWidth <= 750) return ''
  const { match } = props
  const { eventId } = match.params
  const adminProps = useQuery(adminQuery, {
    variables: {
      _id: clientAuth.login()._id
    }
  })
  const eventProps = useQuery(eventQuery, {
    variables: {
      _id: eventId
    }
  })
  const adminLoading = _get(adminProps, 'loading')
  const admin = _get(adminProps, 'data.adminUser')
  const registrationTypeIds = _get(adminProps, 'data.registrationTypeIds')
  const eventLoading = _get(eventProps, 'loading')
  const event = _get(eventProps, 'data.event')

  const { t, i18n } = useTranslation()
  
  const pathname = match.path.split('/')
  const step = match.url.split('/')
  const {roles} = clientAuth.login()
  const MenuLoading = <Sider
    width={200}
    style={{ background: '#fff' }}
    breakpoint="lg"
    collapsedWidth="0"
  >
    <p>
      Loading...
    </p>
  </Sider>
  if (adminLoading && eventLoading) {
    return MenuLoading
  } else if (!(admin && event)) {
    return MenuLoading
  }
  const isQbCustomerIdExist = _get(event, 'eventQb.qbId.length') > 0
  const isQbSupplierIdExist = _get(event, 'eventSupplierQb.qbId.length') > 0
  const isNotExceptionOrganizerId = _get(event, 'organizerId') !== '5a56aad18ec5f75108ac001d'
  const isUnsetQb = !(isQbCustomerIdExist && isQbSupplierIdExist) && isNotExceptionOrganizerId
  const isLineLogin = _get(event, 'isLineLogin') && _get(registrationTypeIds, 'length') === 0

  return (
    <Sider
      width={200}
      style={{ background: '#fff' }}
      // breakpoint="lg"
      // collapsedWidth="0"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={['/']}
        defaultOpenKeys={[pathname[3]]}
        selectedKeys={[step[3]]}
        style={{ height: '100%' }}
      >
        {/* <Menu.SubMenu key="event" title={<span><Icon type="user" />{event.name.en}</span>} onTitleClick={() => {}}> */}
        {/* <Menu.Divider /> */}
        <Menu.Item key="stats">
          <NavLink to={`/events/${eventId}/stats`}>
            <Icon type="line-chart" style={{fontSize: 16}} />
              {t('menu.registrationStat.title')}
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="stats-report">
            <NavLink to={`/events/${eventId}/stats-report`}>
              <Icon type="code" style={{fontSize: 16, color: '#FF0000'}} />
                *Report
            </NavLink>
        </Menu.Item>         */}
        <Menu.Item key="registrations">
          <NavLink to={`/events/${eventId}/registrations`}>
            <Icon type="team" style={{fontSize: 16}} />
            {t('menu.registration.title')}
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="participants">
            <NavLink to={`/events/${eventId}/participants`}>
              <Icon type="user" style={{fontSize: 16}} />
              Participants
            </NavLink>
          </Menu.Item> */}
        {/* <Menu.Item key="tickets">
            <NavLink to={`/events/${eventId}/tickets`}>
              <Icon type="tag-o" style={{fontSize: 16}} />
              Tickets
            </NavLink>
          </Menu.Item> */}
        {!isLineLogin && <Menu.Item key="orders">
          <NavLink to={`/events/${eventId}/orders`}>
            <Icon type="shopping-cart" style={{fontSize: 16}} />
            {t('menu.order.title')}
          </NavLink>
        </Menu.Item>}
        {!isLineLogin && <Menu.Item key="products">
          <NavLink to={`/events/${eventId}/products`}>
            <Icon type="barcode" style={{fontSize: 16}} />
            {t('menu.product.title')}
          </NavLink>
        </Menu.Item>}
        {!isLineLogin && _includes(roles, 'organizer') && <Menu.Item key="edit:content">
          <NavLink to={`/events/${eventId}/edit:content`}>
            <Icon type="edit" style={{fontSize: 16}} />
            {t('menu.product.editContent')}
          </NavLink>
        </Menu.Item>}
        {/* {_includes(roles, 'admin') && <Menu.Item key="edit">
          <NavLink to={`/events/${eventId}/edit`}>
            <Icon type="form" style={{fontSize: 16}} />
              แก้ไขงานวิ่ง
          </NavLink>
        </Menu.Item>} */}
        {(_includes(roles, 'admin') || _includes(admin.abilities, 'editEventEnabled')) &&<SubMenu
            key="edit"
            title={
              <span>
                <Icon type="form" style={{fontSize: 16}} />
                <span>{t('menu.eventEdit.title')}</span>
              </span>
            }
          >
            <Menu.Item key="edit">
              <NavLink to={`/events/${eventId}/edit`}>
                <Icon type="schedule" style={{fontSize: 16}} />
                {t('events.menu.general')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="edit:shirts">
              <NavLink to={`/events/${eventId}/edit:shirts`}>
                <Icon type="skin" style={{fontSize: 16}} />
                {t('events.menu.shirt')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="edit:races">
              <NavLink to={`/events/${eventId}/edit:races`}>
                <Icon type="trophy" style={{fontSize: 16}} />
                {t('events.menu.race')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="edit:propertyquestions">
              <NavLink to={`/events/${eventId}/edit:propertyquestions`}>
                <Icon type="question" style={{fontSize: 16}} />
                {t('events.menu.propertyQuestion')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="edit:tickettypes">
              <NavLink to={`/events/${eventId}/edit:tickettypes`}>
                <Icon type="tags-o" style={{fontSize: 16}} />
                {t('events.menu.ticket')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="edit:registrationtypes">
              <NavLink to={`/events/${eventId}/edit:registrationtypes`}>
                <Icon type="form" style={{fontSize: 16}} />
                {t('events.menu.registrantion')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="edit:content">
              <NavLink to={`/events/${eventId}/edit:content`}>
                <Icon type="file-text" style={{fontSize: 16}} />
                {t('events.menu.content')}
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="4">Option 4</Menu.Item> */}
            {/* <SubMenu key="sub1-2" title="Submenu">
              <Menu.Item key="5">Option 5</Menu.Item>
              <Menu.Item key="6">Option 6</Menu.Item>
            </SubMenu> */}
          </SubMenu>}
        {_includes(roles, 'admin') && config.hiddenField && <Menu.Item key="quick-books">
          <NavLink to={`/events/${eventId}/quick-books`}>
            <Icon type="rise" style={{fontSize: 16}} />
              {isUnsetQb?
                <span style={{color: 'red'}}>!!!QuickBook</span>:
                `QuickBook`
              }
          </NavLink>
        </Menu.Item>}
        {(_includes(roles, 'admin') || _includes(admin.abilities, 'coupon')) && <Menu.Item key="coupon-code-types">
          <NavLink to={`/events/${eventId}/coupon-code-types/list`}>
            <Icon type="gift" style={{fontSize: 16}} />
              {t('menu.couponcode.title')}
          </NavLink>
        </Menu.Item>}
        {/* <Menu.Item key="refund-requests">
          <NavLink to={`/events/${eventId}/refund-requests`}>
            <Icon type="swap" style={{fontSize: 16}} />
              การคืนเงิน
          </NavLink>
        </Menu.Item> */}
        {!isLineLogin && config.hiddenField && <Menu.Item key="cancel-requests">
          <NavLink to={`/events/${eventId}/cancel-requests`}>
            <Icon type="close-circle" style={{fontSize: 16}} />
              {t('menu.refund.title')}
          </NavLink>
        </Menu.Item>}
        <Menu.Item key="runs">
          <NavLink to={`/events/${eventId}/runs`}>
            <Icon type="environment" style={{fontSize: 16}} />
              {t('menu.virtualRun.title')}
          </NavLink>
        </Menu.Item>
        {_includes(roles, 'admin') && <Menu.Item key="payment-summary">
          <NavLink to={`/events/${eventId}/payment-summary`}>
            <Icon type="bar-chart" style={{fontSize: 16}} />
            {t('menu.payment.title')}
          </NavLink>
        </Menu.Item>}
        {config.hiddenField && <Menu.Item key="withdraw-requests">
          <NavLink to={`/events/${eventId}/withdraw-requests`}>
            <Icon type="snippets" style={{fontSize: 16}} />
            {t('menu.tranfer.title')}
          </NavLink>
        </Menu.Item>}
        <Menu.Item key="downloads">
          <NavLink to={`/events/${eventId}/downloads`}>
            <Icon type="download" style={{fontSize: 16}} />
            {t('menu.report.title')}
          </NavLink>
        </Menu.Item>
        {_includes(roles, 'admin') && <Menu.Item key="tools">
          <NavLink to={`/events/${eventId}/tools`}>
            <Icon type="tool" style={{fontSize: 16}} />
            {t('menu.tools.title')}
          </NavLink>.
        </Menu.Item>}
        {_includes(roles, 'admin') && <SubMenu
            key="imports"
            title={
              <span>
                <Icon type="import" style={{fontSize: 16}} />
                <span>{t('menu.import.title')}</span>
              </span>
            }
          >
          <Menu.Item key="privilege">
            <NavLink to={`/events/${eventId}/imports/privilege`}>
              <Icon type="import" style={{fontSize: 16}} />
              privileges
            </NavLink>
          </Menu.Item>
          <Menu.Item key="tracking">
            <NavLink to={`/events/${eventId}/imports/tracking`}>
              <Icon type="import" style={{fontSize: 16}} />
              tracking
            </NavLink>
          </Menu.Item>
        </SubMenu>}
        {/* </Menu.SubMenu> */}
      </Menu>
    </Sider>
  )
}
// <Menu.SubMenu key="sub1" title={<span><Icon type="user" />subnav 1</span>}>

export default compose(
  withRouter
  // graphql(eventQuery, {
  //   options: ({ match: { params: { eventId } } }) => ({
  //     variables: { eventId }
  //   })
  // })
)(EventSider)
