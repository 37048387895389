import React from 'react'
import { Tag } from 'antd'
const colors = {
  rejected: 'red',
  pending: 'orange',
  approved: 'green',
  verified: 'orange',
  withdrew: 'green'
}


const Itag = (props) => {
  const {type, text, icon} = props
  return <Tag color={colors[type]}>
    {icon && icon} {" "}
    {text}
  </Tag>
}
export default Itag