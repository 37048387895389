import React, { useState } from 'react'
import { Table, Button, Layout, Spin, Icon, Modal } from 'antd'
import Itag from './Tag'
import moment from 'moment'
import { useQuery, useMutation } from '@apollo/react-hooks'
import withdrawRequestsQuery from '~/graphql/queries/eventWithdrawRequests.gql'
import eventQuery from '~/graphql/queries/paymentSummaryMiniEvent.gql'
import TopContent from '~/components/Events/Event/TopContent'
import EventSider from '~/components/Events/Event/Sider'
import MethodsTable from '~/components/Events/Event/PaymentSummary/MethodsTable'

const WithdrawList = (props) => {
  const { match: { params: { eventId } } } = props
  const [visible, setVisible] = useState(false)

  const { data, loading } = useQuery(withdrawRequestsQuery, {
    variables: { eventId },
    fetchPolicy: 'network-only',
    skip: !eventId
  })

  const { data: eventData, loading: eventLoading } = useQuery(eventQuery, {
    variables: {
      _id: eventId
    }
  })

  if(loading || eventLoading) {
    return <Spin size="large" tip="loading..."/>
  }

  // console.log(data)

  const dataSource = data.eventWithdrawRequests

  const dateFormat = 'DD/MM/YYYY HH:mm:ss'

  const statusTag = {
    pending: 'รอตรวจสอบ',
    verified: 'กำลังดำเนินงาน',
    approved: 'กำลังดำเนินงาน',
    rejected: 'กำลังดำเนินงาน',
    withdrew: 'สำเร็จ',
    cancelled: 'ยกเลิก'
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'quotationNumber',
      key: 'quotationNumber'
    },
    {
      title: 'วันที่',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format(dateFormat)
    },
    // {
    //   title: 'จำนวนบัญชี',
    //   dataIndex: 'bankCount',
    //   key: 'bankCount'
    // },
    {
      title: 'ยอดเงิน',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'ใบสรุปค่าใช้จ่าย',
      dataIndex: 'summary',
      key: 'summary',
      render: (text) => <Icon type="eye" theme="twoTone" onClick={()=> setVisible(true)} />
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Itag text={statusTag[status]} type={status} />
    },
    {
      title: 'บัญชี',
      dataIndex: 'bankAccountName',
      key: 'bankAccountName'
    },
    
  ]

  const onWithdrawCreate = () => {
    props.history.push(`/events/${eventId}/withdraw-requests/create`)
  }

  return (
   <Layout>
    <Layout.Content>
      <TopContent
        title={'Withdraw'}
        pattern='withdraw'
      />
      <div className="innerMainContentWrapper">
        <EventSider />
        <div className="middleContent">
          <div style={{ backgroundColor: "#fff", borderRadius: '8px', padding: '20px', marginBottom: '20px'}}>
            <h2>ถอนเงิน</h2>
            {/* <MethodsTableClone event={eventData.event} eventId={eventId} /> */}
            <Button onClick={() => onWithdrawCreate()}>ขอถอนเงิน</Button>
          </div>
          <div style={{ backgroundColor: "#fff", borderRadius: '8px', padding: '20px'}}>
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </div>
        <Modal
          title="ใบสรุปค่าใช้จ่าย"
          visible={visible}
          onOk={()=> setVisible(false)}
          onCancel={()=> setVisible(false)}
          width={700}
        >
          <MethodsTable event={eventData.event} eventId={eventId}/>
        </Modal>
      </div>
    </Layout.Content>
   </Layout>
  )

}

export default WithdrawList