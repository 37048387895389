import React from 'react'
import { Table, Button, Row, Col } from 'antd'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import _last from 'lodash/last'
import ConvertValue from './ConvertValue'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import clientAuth from '~/utils/client-auth'

const {
  toFeesAmount,
  toOrganizerBalance
} = ConvertValue
const withCols = [150 + 20 + 40, 60, 60, 60] // < 595
const ExpandedCouponTypeSummaryTable = ({eventPaymentStatistics, eventProductStatistics,
  eventWithdrawRequests, event, sumTransaction}) => {
  // const [step1Last, setStep1Last] = useState(1)
  let balance = 0
  let thairunChargeAmount = 0
  let productSum
  // let step1Stack = 1
  let stackIndex = 0
  // useEffect(()=>{
  //   setStep1Last(step1Stack)
  // }, [step1Stack])
  // console.log({eventPaymentStatistics})
  // console.log(sumTransaction)
  const partnerReturnAmount = _get(sumTransaction, 'partnerReturnAmount') || 0
  const promotionsDiscount = _get(sumTransaction, 'promotionsDiscount') || 0
  const { t } = useTranslation()
  const { roles } = clientAuth.login()

  const sumDeliveryFees = (deliveryFees) => {
    return deliveryFees.reduce((init, current) => {
      let basePlusPriceCount = current.basePlusPrice || current.chargeBasePlusPrice ? current.basePlusPriceCount : 0
      let unitCount = current.pricePerUnit || current.chargePricePerUnit ? current.unitCount : 0
      let ticketDeliveryFees = current.ticketDeliveryFees
      let organizerGatewayDeliveryFee = current.organizerGatewayDeliveryFee

      return {
        basePlusPriceCount: init.basePlusPriceCount + basePlusPriceCount,
        unitCount: init.unitCount + unitCount,
        organizerGatewayDeliveryFee: init.organizerGatewayDeliveryFee + organizerGatewayDeliveryFee,
        ticketDeliveryFees: init.ticketDeliveryFees + ticketDeliveryFees
      }
    }, {
      basePlusPriceCount: 0,
      unitCount: 0,
      organizerGatewayDeliveryFee: 0,
      ticketDeliveryFees: 0
    })
  }
  const step1Ticket = (data) => {
    const columns = [
      {
        title: `Step 1 ${t('summary.stepOne.title')}`,
        // dataIndex: 'ticketType',
        key: `ticketType`,
        width: withCols[0],
        style: {color: 'red'},
        render: ({ticketType, couponCodeType}) => {
          stackIndex = stackIndex + 1
          let couponCodeTypeName = _get(couponCodeType, 'name.th')
            ? ` (${_get(couponCodeType, 'name.th')})`
            : ``
          if (couponCodeType === 'promotionsDiscount') {
            ticketType = {
              name: {
                en: 'ค่าส่วนลดโปรโมชั่น'
              }
            }
          } else if (couponCodeType === 'partnerReturnAmount') {
            ticketType = {
              name: {
                en: 'คืนค่า Bank fee'
              }
            }
          }
          return <div>
            {`[${stackIndex}] `}{_get(ticketType, 'name.en')}{' '}
            <i>
              {couponCodeTypeName}
            </i>
          </div>
        }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        width: withCols[1],
        dataIndex: 'participantCount',
        key: `perTicketCount`
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        dataIndex: 'ticketDiscounted',
        key: `ticketDiscounted`,
        width: withCols[2],
        render: value => (toOrganizerBalance(value))
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        dataIndex: 'ticketDiscountedAmount',
        key: `ticketDiscountedAmount`,
        width: withCols[3],
        render: value => (toOrganizerBalance(value))
      }
    ]
    if (!(_get(data, 'length') > 0)) return ''
    const payments = data[0].payments
    const couponCodeTypePaymentLength = payments.length
    console.log({payments})
    return _sortBy(payments, 'couponCodeTypePayment.ticketType._id').map((couponCodeTypePayment, index) => {
      if (couponCodeTypePayment.payments) {
        let tbClassNames = 'metaPaymentSummary'
        if (index === 0) tbClassNames = 'metaPaymentSummaryFirst'
        const ticketTypeLength = couponCodeTypePayment.payments.length
        const payments = _sortBy(couponCodeTypePayment.payments, 'ticketType._id').map(payment => {
          return {
            ...payment,
            // ticketDeliveryFees: couponCodeTypePayment.ticketDeliveryFees,
            ticketChargeDeliveryFees: couponCodeTypePayment.ticketChargeDeliveryFees,
            ticketTypeLength
          }
        })
        console.log(couponCodeTypePaymentLength, index)
        if(promotionsDiscount > 0 && couponCodeTypePaymentLength -1 === index) {
          payments.push({
            amount: 0,
            couponCodeType: 'promotionsDiscount',
            discount: 0,
            feesAmount: 0,
            gatewayFeesAmount: 0,
            organizerBalance: 0,
            organizerGatewayFeesAmount: 0,
            participantCount: 0,
            perFreeCouponCodeFees: 0,
            perParticipantThairunFees: 0,
            percentageThairunFees: 0,
            ticketDiscounted: 0,
            ticketDiscountedAmount: -promotionsDiscount
          })
        }
        if(partnerReturnAmount > 0 && couponCodeTypePaymentLength -1 === index) {
          payments.push({
            amount: 0,
            couponCodeType: 'partnerReturnAmount',
            discount: 0,
            feesAmount: 0,
            gatewayFeesAmount: 0,
            organizerBalance: 0,
            organizerGatewayFeesAmount: 0,
            participantCount: 0,
            perFreeCouponCodeFees: 0,
            perParticipantThairunFees: 0,
            percentageThairunFees: 0,
            ticketDiscounted: 0,
            ticketDiscountedAmount: partnerReturnAmount
          })
        }
        return (
          <div key={`${_get(couponCodeTypePayment, 'couponCodeType._id')}${index}`}>
            <Table
              key={`${_get(couponCodeTypePayment, 'couponCodeType._id')}${index}`}
              bordered
              className={tbClassNames}
              columns={columns}
              defaultExpandAllRows={true}
              dataSource={payments}
              pagination={false}
            />
          </div>
        )
      }
      return ''
    })
  }

  const step1TicketSum = (data) => {
    const columns = [
      {
        title: `${t('summary.stepOne.total')}`,
        key: `step1SumTitle`,
        width: withCols[0],
        render: () => {
          stackIndex = stackIndex + 1
          return `[${stackIndex}] ${t('summary.stepOne.total')}`
        }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        dataIndex: 'participantCount',
        width: withCols[1],
        key: `participantCount`
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        key: `ticketDiscounted`,
        width: withCols[2],
        render: () => ''
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        key: `step1Sum`,
        width: withCols[3],
        render: ({ticketDiscountedAmount}) => (
          toOrganizerBalance(ticketDiscountedAmount - promotionsDiscount + partnerReturnAmount))
      }
    ]
    if (!(_get(data, 'length') > 0)) return ''
    // const sumClass = step1Last === step1Stack? 'sum' : 'sumTop'
    // step1Stack = step1Stack + 1
    return <Table
      key={`step1Sum`}
      bordered
      className={`metaPaymentSummary`}
      columns={columns}
      defaultExpandAllRows={true}
      dataSource={[data[0]]}
      pagination={false}
    />
  }

  const step1Product = (data) => {
    const columns = [
      {
        title: `${t('summary.stepOne.products.title')}`,
        // dataIndex: 'ticketType',
        key: `product`,
        width: withCols[0],
        style: {color: 'red'},
        render: ({product, orderItemType}) => {
          stackIndex = stackIndex + 1
          return <div>
            {`[${stackIndex}] `}{_get(product, 'name.th')}{' '}
            <i>
              {_get(orderItemType, 'name.th')}
            </i>
          </div>
          }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        width: withCols[1],
        dataIndex: 'count',
        key: `count`
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        // dataIndex: 'product.price',
        key: `price`,
        width: withCols[2],
        render: ({product, orderItemType}) => {
          const price = (_get(product, 'openorderitemtype') ? _get(orderItemType, 'price') : _get(product, 'price')) || 0
          return toOrganizerBalance(price)
        }
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        dataIndex: 'amount',
        key: `amount`,
        width: withCols[3],
        render: value => (toOrganizerBalance(value))
      }
    ]
    if (!(_get(data, 'length') > 0)) return ''

    return <Table
      key={`product`}
      bordered
      // className={data}
      columns={columns}
      defaultExpandAllRows={true}
      dataSource={data}
      pagination={false}
    />
  }

  const step1ProductSum = (data) => {
    const columns = [
      {
        title: `${t('summary.stepOne.total')}`,
        key: `step1ProductSumTitle`,
        width: withCols[0],
        render: () => {
          stackIndex = stackIndex + 1
          return `[${stackIndex}] ${t('summary.stepOne.total')}`
        }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        dataIndex: 'count',
        width: withCols[1],
        key: `count`
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        key: `baseAmount`,
        width: withCols[2],
        render: () => ''
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        dataIndex: 'amount',
        key: `amount`,
        width: withCols[3],
        render: (amount) => toOrganizerBalance(amount)
      }
    ]
    if (!(_get(data, 'length') > 0)) return ''
    const sum = data.reduce((init, current) => {
      return {
        count: init.count + current.count,
        amount: init.amount + current.amount
      }
    }, {
      count: 0,
      amount: 0
    })
    productSum = sum
    // const sumClass = step1Last === step1Stack? 'sum' : 'sumTop'
    // step1Stack = step1Stack + 1
    return <Table
      key={`step1Sum`}
      bordered
      className={`metaPaymentSummary`}
      columns={columns}
      defaultExpandAllRows={true}
      dataSource={[sum]}
      pagination={false}
    />
  }

  const step1SplitTickeDeliverly = (data) => {
    const columns = [
      {
        title: `${t('summary.stepOne.shipping')}`,
        key: `ticketDeliveryFeesTitle`,
        width: withCols[0],
        render: ({title}) => {
          stackIndex = stackIndex + 1
          return `[${stackIndex}] ${title}`
        }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        width: withCols[1],
        dataIndex: 'count',
        key: `count`,
        render: (count) => count
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        width: withCols[2],
        dataIndex: 'price',
        key: `price`,
        render: (price) => price
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        key: `ticketDeliveryFees`,
        width: withCols[3],
        render: ({price, count}) => toOrganizerBalance(price * count)
      }
    ]
    if (!(_get(data, 'length') > 0)) return ''
    return <Table
      key={`step1DeliveryHeader`}
      bordered
      className='metaPaymentSummary'
      columns={columns}
      defaultExpandAllRows={true}
      dataSource={data}
      pagination={false}
    />
  }

  const step1TicketDeliverySum = (data) => {
    const columns = [
      {
        title: `${t('summary.stepOne.total')}`,
        key: `step1SumTitle`,
        width: withCols[0],
        render: () => {
          stackIndex = stackIndex + 1
          return `[${stackIndex}] ${t('summary.stepOne.total ')}`
        }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        width: withCols[1],
        render: ({basePlusPriceCount, unitCount}) => basePlusPriceCount + unitCount
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        key: `ticketDiscounted`,
        width: withCols[2],
        render: () => ''
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        key: `step1Sum`,
        dataIndex: 'ticketDeliveryFees',
        width: withCols[3],
        render: (ticketDeliveryFees) => toOrganizerBalance(ticketDeliveryFees)
      }
    ]
    const sum = sumDeliveryFees(data)
    // const sumClass = step1Last === step1Stack? 'sum' : 'sumTop'
    return <Table
      key={`step1TicketDeliverySum`}
      bordered
      className={`metaPaymentSummary`}
      columns={columns}
      defaultExpandAllRows={true}
      dataSource={[sum]}
      pagination={false}
    />
  }

  const step1TicketDeliverly = (data) => {
    const columns = [
      {
        title: `${t('summary.stepOne.shipping')}`,
        key: `ticketDeliveryFeesTitle`,
        width: withCols[0],
        render: () => 'ค่าไปรษณีย์'
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        width: withCols[1],
        key: `participantCount`,
        render: () => ''
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        key: `ticketDiscounted`,
        width: withCols[2],
        render: () => ''
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        key: `ticketDeliveryFees`,
        width: withCols[3],
        render: () => ''
      }
    ]
    if (!(_get(data, 'length') > 0)) return ''
    if (!(_get(data[0], 'ticketDeliveryFees.length') > 0)) return ''
    const deliveryFees = _get(data[0], 'ticketDeliveryFees')
    // if (!deliveryFees[0]._id) return ''
    return <div>
      <Table
        key={`step1DeliveryHeader`}
        bordered
        className='onlyHeader'
        columns={columns}
        defaultExpandAllRows={true}
        dataSource={[1]}
        pagination={false}
      />
      {deliveryFees.map(d => {
        const basePlusPrice = _get(d, 'basePlusPrice')
        const basePlusPriceCount = _get(d, 'basePlusPriceCount')
        const pricePerUnit = _get(d, 'pricePerUnit')
        const chargeBasePlusPrice = _get(d, 'chargeBasePlusPrice')
        const chargePricePerUnit = _get(d, 'chargePricePerUnit')
        const unitCount = _get(d, 'unitCount')
        if (!d.registrationType) return ''
        if (!d.registrationType.deliveryOptions) return ''
        const deliveryOption = d.registrationType.deliveryOptions.find(o => o._id === d._id)
        if (!deliveryOption) return ''
        const title = `${_get(deliveryOption, 'name.th')} (${_get(d, 'registrationType.name.th')})`
        data = []
        if (basePlusPrice > 0 || chargeBasePlusPrice > 0) {
          data.push({price: basePlusPrice, count: basePlusPriceCount, title})
        }
        if (pricePerUnit > 0 || chargePricePerUnit > 0) {
          data.push({price: pricePerUnit, count: unitCount, title})
        }
        return step1SplitTickeDeliverly(data)
      })}
      {step1TicketDeliverySum(deliveryFees)}
    </div>
  }

  const step1SumTotal = ({tickets, products}) => {
    const columns = [
      {
        title: `${t('summary.stepOne.total')}`,
        key: `step1SumTitle`,
        width: withCols[0],
        render: () => {
          stackIndex = stackIndex + 1
          return `[${stackIndex}] ${t('summary.stepOne.total')}`
        }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        dataIndex: 'participantCount',
        width: withCols[1],
        key: `participantCount`
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        key: `ticketDiscounted`,
        width: withCols[2],
        render: () => ''
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        key: `step1Sum`,
        width: withCols[3],
        render: ({step1TotalSum}) => (
          toOrganizerBalance(step1TotalSum))
      }
    ]
    // if (!(_get(data, 'length') > 0)) return ''
    let step1TotalSum = tickets[0].ticketDiscountedAmount - promotionsDiscount + partnerReturnAmount
    
    if (_get(products, 'length') > 0) {
      step1TotalSum = step1TotalSum + productSum.amount
    }
    if (_get(tickets[0], 'ticketDeliveryFees.length') > 0) {
      const deliveryFees = _get(tickets[0], 'ticketDeliveryFees')
      // console.log('organizerGatewayDeliveryFee', deliveryFees.organizerGatewayDeliveryFee)
      const sum = sumDeliveryFees(deliveryFees)
      step1TotalSum = step1TotalSum + sum.ticketDeliveryFees
    }
    const data = [{ step1TotalSum }]
    return <Table
      key={`step1SumTotal`}
      bordered
      className={`metaPaymentSummary sum`}
      columns={columns}
      defaultExpandAllRows={true}
      dataSource={data}
      pagination={false}
    />
  }

  const step2Fees = ({tickets, products}) => {
    const columns = [
      {
        title: `Step 2 ${t('summary.stepTwo.title')}`,
        // dataIndex: 'title',
        key: `title`,
        width: withCols[0],
        render: ({title}) => {
          stackIndex = stackIndex + 1
          return <span>
            [{stackIndex}] {title}
          </span>
        }
      }, {
        title: `${t('summary.stepOne.quantity')}`,
        dataIndex: 'count',
        width: withCols[1]
      },
      {
        title: `${t('summary.stepOne.Unit')}`,
        key: `price`,
        dataIndex: 'price',
        width: withCols[2]
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        dataIndex: 'amount',
        width: withCols[3],
        key: 'amount'
      }
    ]
    const data = []
    const total = []
    // if (partnerReturnAmount > 0) {
    //   console.log('partnerReturnAmount', partnerReturnAmount)
    //   balance = balance + partnerReturnAmount
    //   data.push({title: 'คืนค่า Bank fee', count: '', price: '', amount: toOrganizerBalance(partnerReturnAmount)})
    // }
    if (_get(tickets, 'length') > 0) {
      balance = balance + tickets[0].ticketDiscountedAmount - promotionsDiscount + partnerReturnAmount
      // data.push({title: 'ยอดรายรับค่าสมัคร', count: '', price: '', amount: toOrganizerBalance(tickets[0].ticketDiscountedAmount)})
      if (_get(products, 'length') > 0) {
        balance = balance + productSum.amount
        // data.push({title: 'ยอดรายรับจากสินค้าพวง', count: '', price: '', amount: toOrganizerBalance(productSum.amount)})
      }
      if (_get(tickets[0], 'ticketDeliveryFees.length') > 0) {
        const deliveryFees = _get(tickets[0], 'ticketDeliveryFees')
        // console.log('organizerGatewayDeliveryFee', deliveryFees.organizerGatewayDeliveryFee)
        const sum = sumDeliveryFees(deliveryFees)
        balance = balance + sum.ticketDeliveryFees
        // data.push({title: 'ยอดค่าไปรษณีย์', count: '', price: '', amount: toOrganizerBalance(sum.ticketDeliveryFees)})
      }
      // if (promotionsDiscount > 0) {
      //   const discount = promotionsDiscount
      //   balance = balance - discount
      //   thairunChargeAmount = thairunChargeAmount + discount
      //   data.push({title: 'ค่าส่วนลดโปรโมชั่น', count: '', price: '', amount: toFeesAmount(discount)})
      // }
      const charge = event.settings.charge
      if (_get(charge, 'percentage')) {
        const thairunChargePercent = charge.percentage * balance
        thairunChargeAmount = thairunChargeAmount + thairunChargePercent
        data.push({title: 'ค่าบริการ (แบบคิด %)',
          // count: tickets[0].percentageThairunFeeCount,
          // count: toOrganizerBalance(tickets[0].ticketDiscountedAmount),
          count: toOrganizerBalance(balance),
          price: `${(charge.percentage * 100).toFixed(2)}%`,
          amount: toFeesAmount(thairunChargePercent)})
        balance = balance - thairunChargePercent
      }
      const chargeParticipantMin = _get(charge, 'participantMin') || 0
      const twoSectionParticipantInitNumber = _get(charge, 'twoSectionParticipantInitNumber') || 0
      const twoSectionParticipantInitBasePrice = _get(charge, 'twoSectionParticipantInitBasePrice') || 0
      const twoSectionParticipantNextBasePrice = _get(charge, 'twoSectionParticipantNextBasePrice') || 0
      const participantCount = _get(tickets, [0, 'participantCount']) || 0
      console.log('tickets[0]', tickets[0])
      if (participantCount > 0 && twoSectionParticipantInitNumber > 0) {
        const chargeTwoSectionParticipantInitAmount = twoSectionParticipantInitNumber * twoSectionParticipantInitBasePrice
        thairunChargeAmount = thairunChargeAmount + chargeTwoSectionParticipantInitAmount
        balance = balance - chargeTwoSectionParticipantInitAmount
        data.push({title: `ค่าบริการ (แบบคิด ต่อหัว คิดจากจำนวนผู้สมัครขั้นต่ำ ${twoSectionParticipantInitNumber} คนแรก)`,
          count: `${twoSectionParticipantInitNumber}`,
          price: `${twoSectionParticipantInitBasePrice}`,
          amount: toFeesAmount(chargeTwoSectionParticipantInitAmount)})
        if(participantCount > twoSectionParticipantInitNumber) {
          const remainPartCount = (participantCount - twoSectionParticipantInitNumber)
          const chargeTwoSectionParticipantInitAmount = remainPartCount * twoSectionParticipantNextBasePrice
          thairunChargeAmount = thairunChargeAmount + chargeTwoSectionParticipantInitAmount
          balance = balance - chargeTwoSectionParticipantInitAmount
          data.push({title: `ค่าบริการ (แบบคิด ต่อหัว คิดจากจำนวนผู้สมัครคนที่ ${twoSectionParticipantInitNumber + 1})`,
            count: `${remainPartCount}`,
            price: `${twoSectionParticipantNextBasePrice}`,
            amount: toFeesAmount(chargeTwoSectionParticipantInitAmount)})
        }
      }
      if (tickets[0].perParticipantThairunFeeCount || tickets[0].perTicketCount) {
        if (tickets[0].perTicketCount < chargeParticipantMin) {
          const chargePerCondition = charge.perTicket || charge.perParticipant
          const chargePerParticipantAmount = chargePerCondition * chargeParticipantMin
          thairunChargeAmount = thairunChargeAmount + chargePerParticipantAmount
          balance = balance - chargePerParticipantAmount
          data.push({title: 'ค่าบริการ (แบบคิด ต่อหัว  คิดจากจำนวนผู้สมัครขั้นต่ำ)',
            count: chargeParticipantMin,
            price: chargePerCondition,
            amount: toFeesAmount(chargePerParticipantAmount)})
        } else {
          if (tickets[0].perTicketThairunFees > 0) {
            thairunChargeAmount = thairunChargeAmount + tickets[0].perTicketThairunFees
            balance = balance - tickets[0].perTicketThairunFees
            data.push({title: `${t('summary.stepTwo.serviceFee')}`,
              count: tickets[0].perTicketCount,
              price: charge.perTicket,
              amount: toFeesAmount(tickets[0].perTicketThairunFees)})
          } else {
            thairunChargeAmount = thairunChargeAmount + tickets[0].perParticipantThairunFees
            balance = balance - tickets[0].perParticipantThairunFees
            data.push({title: `${t('summary.stepTwo.serviceFee')}`,
              count: tickets[0].perParticipantThairunFeeCount,
              price: charge.perParticipant,
              amount: toFeesAmount(tickets[0].perParticipantThairunFees)})
          }
        }
      }

      if (tickets[0].perCouponCodeCount) {
        const perCouponCodeThairunFees = tickets[0].perCouponCodeCount * charge.perCouponCode
        thairunChargeAmount = thairunChargeAmount + perCouponCodeThairunFees
        balance = balance - perCouponCodeThairunFees
        data.push({title: 'ค่าบริการ Code',
          count: tickets[0].perCouponCodeCount,
          price: charge.perCouponCode,
          amount: toFeesAmount(perCouponCodeThairunFees)})
      }
      if (tickets[0].perFreeCouponCodeFees) {
        thairunChargeAmount = thairunChargeAmount + tickets[0].perFreeCouponCodeFees
        balance = balance - tickets[0].perFreeCouponCodeFees
        data.push({title: 'ค่าบริการ ตั๋ว free',
          count: tickets[0].perFreeCouponCodeFeeCount,
          price: charge.perFreeCouponCode,
          amount: toFeesAmount(tickets[0].perFreeCouponCodeFees)})
      }
      // console.log('tickets[0]', tickets[0])
      if (tickets[0].organizerGatewayFeesAmount > 0) {
        thairunChargeAmount = thairunChargeAmount + tickets[0].organizerGatewayFeesAmount
        balance = balance - tickets[0].organizerGatewayFeesAmount
        // data.push({title: 'ค่าบริการ Bank fee ตั๋ว',
        //   count: '',
        //   price: '',
        //   amount: toFeesAmount(tickets[0].organizerGatewayFeesAmount)})
      }
      if (
        tickets[0].organizerGatewayFeesAmount > 0
        && _get(tickets, [0, 'BankfeeList', 'length']) > 0
      ) {
        _get(tickets, [0, 'BankfeeList'])
          .filter(t => t._id !== 'free')
          .map(b => {
            // thairunChargeAmount = thairunChargeAmount + tickets[0].organizerGatewayFeesAmount
            // balance = balance - tickets[0].organizerGatewayFeesAmount
            let method = b._id
            if(b._id === 'omise_card') {
              method = 'credit card'
            } else if(b._id === 'scb_qr') {
              method = 'QR code'
            } else if(b._id === 'omise_promptpay') {
              method = 'promptpay'
            } else if(b._id === 'omise_truemoney') {
              method = 'truemoney'
            }
            // console.log('toOrganizerBalance(b.gatewayFee)', toOrganizerBalance(b.gatewayFee))
            data.push({title: `ค่าบริการ Bank fee [${method}]`,
              count: toOrganizerBalance(b.ticketDiscounted),
              price: [toOrganizerBalance(b.gatewayFee), ' %'],
              // price: toOrganizerBalance(b.gatewayFee),
              amount: toFeesAmount(b.organizerGatewayFeesAmount)})
            })
      }
      if (_get(products, 'length') > 0) {
        products.forEach(({product, orderItemType, count, organizerGatewayFee}) => {
          const price = (_get(product, 'openorderitemtype') ? _get(orderItemType, 'price') : _get(product, 'price')) || 0
          const chargePercentage = _get(product, 'chargePercentage') || 0
          const chargePerItem = _get(product, 'chargePerItem') || 0
          const amount = price * count
          const chargedAmount = (amount * chargePercentage) + (chargePerItem * count)
          thairunChargeAmount = thairunChargeAmount + chargedAmount
          balance = balance - chargedAmount
          const title = <span>
            ค่าบริการ สินค้าพวง
            {_get(product, 'name.th')}{' '}
            <i>
              {_get(orderItemType, 'name.th')}
            </i>
          </span>
          const titlePrice = <span>
            {chargePercentage > 0 && <span>
              ({chargePercentage * 100 } %)
            </span>}
            {chargePerItem > 0 && <span>
              ({chargePerItem} บาท * { count } )
            </span>}
          </span>
          data.push({title, count: toOrganizerBalance(amount), price: titlePrice, amount: toFeesAmount(chargedAmount)})
          if (organizerGatewayFee > 0) {
            thairunChargeAmount = thairunChargeAmount + organizerGatewayFee
            balance = balance - organizerGatewayFee
            const title = <span>
            ค่า Bankfee สินค้าพวง
              {_get(product, 'name.th')}{' '}
              <i>
                {_get(orderItemType, 'name.th')}
              </i>
            </span>
            data.push({title, count: '', price: '', amount: toFeesAmount(organizerGatewayFee)})
          }
        })
      }
      if (_get(tickets[0], 'ticketDeliveryFees.length') > 0) {
        const deliveryFees = _get(tickets[0], 'ticketDeliveryFees')
        deliveryFees.forEach(d => {
          const chargeBasePlusPrice = _get(d, 'chargeBasePlusPrice')
          const chargeBasePlusPriceCount = _get(d, 'chargeBasePlusPriceCount')
          const chargePricePerUnit = _get(d, 'chargePricePerUnit')
          const chargeUnitCount = _get(d, 'chargeUnitCount')
          const basePlusPrice = _get(d, 'basePlusPrice')
          const basePlusPriceCount = _get(d, 'basePlusPriceCount')
          const pricePerUnit = _get(d, 'pricePerUnit')
          const unitCount = _get(d, 'unitCount')
          const organizerGatewayDeliveryFee = _get(d, 'organizerGatewayDeliveryFee')
          if (!d.registrationType) return ''
          if (!d.registrationType.deliveryOptions) return ''
          const deliveryOption = d.registrationType.deliveryOptions.find(o => o._id === d._id)
          if (deliveryOption) {
            let title = `${_get(deliveryOption, 'name.th')} (${_get(d, 'registrationType.name.th')})`
            // if (basePlusPrice > 0 || chargeBasePlusPrice > 0) {
            if (chargeBasePlusPrice > 0) {
              let price = chargeBasePlusPrice
              let fee = chargeBasePlusPrice * chargeBasePlusPriceCount || 0
              let amount = toFeesAmount(fee)
              let count = chargeBasePlusPriceCount
              if (basePlusPrice === 0 && chargeBasePlusPrice > 0) { // ค่าจ้างไทยรันส่งไปรษณีย์
                title = [`ค่าจ้างไทยรันส่งไปรษณีย์ `, <i>{title}</i>]
              } else if (basePlusPrice > 0 && chargeBasePlusPrice > 0) {
                // title = [`ค่าบริการ Bank fee`, <br/>, <i>{title}</i>]
                title = [`ค่าบริการ Bank fee `, <i>{title}</i>]
                count = toOrganizerBalance(basePlusPrice * basePlusPriceCount)
                // price = `${(charge.percentage * 100).toFixed(2)}%`
                // fee = charge.percentage * basePlusPrice * basePlusPriceCount
                price = `${chargeBasePlusPrice} บาท`
                fee = chargeBasePlusPrice * basePlusPriceCount
                amount = toFeesAmount(fee)
                balance = balance - fee
              } else {
                balance = balance - fee
              }
              data.push({title, count, price, amount})
            }
            // if (pricePerUnit > 0 || chargePricePerUnit > 0) {
            if (chargePricePerUnit > 0) {
              title = `${_get(deliveryOption, 'name.th')} (${_get(d, 'registrationType.name.th')})`
              let price = chargePricePerUnit
              let fee = (chargeUnitCount * chargePricePerUnit) || 0
              let amount = toFeesAmount(chargeUnitCount * fee)
              let count = chargeUnitCount
              if (pricePerUnit === 0 && chargePricePerUnit > 0) { // ค่าจ้างไทยรันส่งไปรษณีย์
                title = [`ค่าจ้างไทยรันส่งไปรษณีย์ `, <i>{title}</i>]
              } else if (pricePerUnit > 0) {
                title = [`ค่าบริการ Bank fee `, <i>{title}</i>]
                count = toOrganizerBalance(pricePerUnit * unitCount)
                // price = `${(charge.percentage * 100).toFixed(2)}%`
                // fee = charge.percentage * pricePerUnit * unitCount
                price = `${chargePricePerUnit} บาท`
                fee = chargePricePerUnit * unitCount
                amount = toFeesAmount(fee)
                balance = balance - fee
              } else {
                balance = balance - fee
              }
              data.push({title, count, price, amount})
            }
            if (organizerGatewayDeliveryFee > 0) {
              const title = `ค่าบริการ Bank fee ${_get(deliveryOption, 'name.th')} (${_get(d, 'registrationType.name.th')})`
              data.push({title, count: '', price: '', amount: toFeesAmount(organizerGatewayDeliveryFee)})
              thairunChargeAmount = thairunChargeAmount + organizerGatewayDeliveryFee
              balance = balance - organizerGatewayDeliveryFee
            }
          }
        })
      }
      const vat = thairunChargeAmount * 0.07

      data.push({title: `${t('summary.stepTwo.vat')}`, count: toOrganizerBalance(thairunChargeAmount), price: '7%', amount: toFeesAmount(vat)})
      thairunChargeAmount = thairunChargeAmount + vat
      balance = balance - vat
    }
    total.push({title: `${t('summary.stepOne.total')}`, count: '', price: '', amount: toFeesAmount(thairunChargeAmount)})
    return <div>
      <Table
        key={`balanceCals`}
        bordered
        className='marginTop'
        columns={columns}
        defaultExpandAllRows={true}
        dataSource={data}
        pagination={false}
      />
      <Table
        key={`balance`}
        bordered
        className='metaPaymentSummary sum'
        columns={columns}
        defaultExpandAllRows={true}
        dataSource={total}
        pagination={false}
      />
    </div>
  }
  const step3Withdraws = (withdraws) => {
    withdraws = withdraws.filter((item) => item.status === 'withdrew')
    const columns = [
      {
        title: `Step 3 ${t('summary.stepThree.title')}`,
        // dataIndex: 'title',
        key: `title`,
        width: withCols[0],
        render: ({title}) => {
          stackIndex = stackIndex + 1
          return `[${stackIndex}] ${title}`
        }
      }, {
        title: `${t('summary.stepThree.date')}`,
        dataIndex: 'date',
        width: withCols[1]
      },
      {
        title: `${t('summary.accountNo')}`,
        key: `bankAccountNumber`,
        dataIndex: 'bankAccountNumber',
        width: withCols[2]
      },
      {
        title: `${t('summary.stepThree.doc')}`,
        key: `docNumber`,
        dataIndex: 'docNumber',
        width: withCols[3]
      },
      {
        title: `${t('summary.stepOne.amount')}`,
        dataIndex: 'amount',
        width: withCols[3],
        key: 'amount'
      }
    ]
    const total = []
    const data = []
    if (balance > 0) {
      data.push({title: `${t('summary.stepThree.fromstep')}`, date: '', bankAccountNumber: '', docNumber: '', amount: toOrganizerBalance(balance)})
    }
    if (_get(withdraws, 'length') > 0) {
      withdraws.forEach(w => {
        balance = balance - w.amount
        let title = w.amount > 0 ? `${t('summary.stepThree.transferOrg')}` : `${t('summary.stepThree.receiptOrg')}`
        let editName = _get(w, 'editName') || ''
        if(editName.trim().length > 0) title = editName
        title = `${title} ${moment(w.createdAt).locale('th').format('YYYY/MM/DD')}`
        data.push({title, date: moment(w.createdAt).locale('th').format('L'), bankAccountNumber: w.bankAccountNumber, docNumber: `${_get(event, 'code')}`, amount: toOrganizerBalance(-w.amount)})
      })
    } else {
      data.push({title: `${t('summary.stepThree.transferOrg')}`, date: '', bankAccountNumber: '', docNumber: '', amount: 0})
    }
    total.push({
      title: `${t('summary.stepThree.balance')}`,
      date: '',
      bankAccountNumber: '',
      docNumber: '',
      amount: toOrganizerBalance(
        balance < 0 && balance > -0.02 ?
        0 :
        balance
      )
    })
    return <div>
      <Table
        key={`withdraws`}
        bordered
        className='marginTop'
        columns={columns}
        defaultExpandAllRows={true}
        dataSource={data}
        pagination={false}
      />
      <Table
        key={`productAmount`}
        bordered
        className='metaPaymentSummary sum'
        columns={columns}
        defaultExpandAllRows={true}
        dataSource={total}
        pagination={false}
      />
    </div>
  }
  return (
    <div className='paymentSummary'>
      {/* <Button icon='print' onClick={() => exportPdf(this.componentRef)}> ดาวน์โหลด PDF</Button> */}
      {roles.includes('admin') || roles.includes('accountant') && <Button icon='print' className="printPdfBtn" onClick={() => window.print()}> Download PDF</Button>}
      {/* <div ref={el => (this.componentRef = el)} className="paf-output"> */}
      <div className="pdf-output">
        <div className="title topheader">
          {t('summary.expenseSummary')} {_get(event, 'name.en')}
        </div>
        {eventWithdrawRequests && <div>
          <Row justify="space-between" className="topPaymentDetail">
            <Col span={12}>
              <p>{t('summary.quotation')}: {_get(_last(eventWithdrawRequests), 'quotationNumber')}</p>
            </Col>
            <Col span={12}>
              <p>{t('summary.accountName')}: {_get(_last(eventWithdrawRequests), 'bankAccountName')}</p>
            </Col>
            <Col span={12}>
              <p>{t('summary.accountNo')}: {_get(_last(eventWithdrawRequests), 'bankAccountNumber')}</p>
            </Col>
            <Col span={12}>
              <p>{t('summary.bank')}: {_get(_last(eventWithdrawRequests), 'bankAccountBrand')}</p>
            </Col>
            <Col span={12}>
              <p>{t('summary.salesPerson')}: {_get(_last(eventWithdrawRequests), 'salesPersonName')}</p>
            </Col>
            <Col span={12}>
              <p>{t('summary.no')}: {_get(event, 'code')} {moment(_get(_last(eventWithdrawRequests), 'createdAt')).locale('th').format('YYYY/MM/DD')}</p>
            </Col>
            <Col span={12}>
              <p>{t('summary.remark')}: {_get(_last(eventWithdrawRequests), 'remarks')}</p>
            </Col>
            {roles.includes('admin') || roles.includes('accountant') && <Col span={12}>
              <p>{t('summary.supplier')}: {_get(event, 'eventSupplierQb.qbName') || 'NO NAME'}</p>
              <span className="no-print">
              {t('summary.qbBalance')}: {
                _get(event, 'qbVendorBalance') === '-' ?
                  '-' :
                  toOrganizerBalance(parseFloat(_get(event, 'qbVendorBalance')))

                }
              </span>
            </Col>}
          </Row>
        </div>}
        <p/>
        {eventPaymentStatistics && <div className="step1Ticket">
          {step1Ticket(eventPaymentStatistics)}
          {step1TicketSum(eventPaymentStatistics)}
        </div>}
        {eventProductStatistics && <div className="step1Product">
          {step1Product(eventProductStatistics)}
          {step1ProductSum(eventProductStatistics)}
        </div>}
        {eventProductStatistics && <div className="step1Product">
          {step1TicketDeliverly(eventPaymentStatistics)}
        </div>}
        {eventPaymentStatistics && <div className="step1Product">
          {step1SumTotal({tickets: eventPaymentStatistics, products: eventProductStatistics})}
        </div>}
        <div className="step2">
          {step2Fees({tickets: eventPaymentStatistics, products: eventProductStatistics})}
        </div>
        <div className="step3">
          {step3Withdraws(eventWithdrawRequests)}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '50px'}}>
        <p>{t('summary.signature')}</p>
      </div>
    </div>
  )
}
export default ExpandedCouponTypeSummaryTable
